var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "relative invoice-create-page" },
    [
      !_vm.isLoadingInvoice && !_vm.isLoadingData
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.pageTitle } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("general.home"),
                          to: "/admin/dashboard",
                        },
                      }),
                      _vm._v(" "),
                      _vm.shouldShowBreadcrumb
                        ? _c("li", { staticClass: "pr-2 text-sm" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "router-link-exact-active active pr-2 m-0 leading-5 text-gray-900 text-sm cursor-pointer",
                                on: { click: _vm.goToPreviousPage },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$tc("expenses.expense", 2)) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "px-1" }, [_vm._v("/")]),
                          ])
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$tc("expenses.expense", 2),
                              to: "/admin/invoices",
                            },
                          }),
                      _vm._v(" "),
                      _vm.$route.name === "invoices.edit"
                        ? _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$t("expenses.edit_expense"),
                              to: "#",
                              active: "",
                            },
                          })
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$t("expenses.new_expense"),
                              to: "#",
                              active: "",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            disabled: _vm.isLoading,
                            variant: "primary-outline",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goBack.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n          Torna alla lista spese\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "flex justify-center w-full lg:w-auto",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading || !_vm.canEditInvoice,
                            title: _vm.tooltipMessage,
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("expenses.save_expense")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-cols-12 gap-8 mt-6 mb-8 lg:grid" },
                [
                  _c("customer-select", {
                    staticClass: "col-span-4 pr-0",
                    attrs: {
                      valid: _vm.$v.selectedCustomer,
                      "customer-id": _vm.customerId,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-2 col-span-7 gap-4 mt-8 lg:gap-6 lg:mt-0 lg:grid-cols-4",
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("invoices.registration_date"),
                            error: _vm.registrationDateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid:
                                _vm.$v.newInvoice.registration_date.$error,
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newInvoice.registration_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.newInvoice.registration_date,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newInvoice,
                                  "registration_date",
                                  $$v
                                )
                              },
                              expression: "newInvoice.registration_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("invoices.invoice_date"),
                            error: _vm.invoiceDateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newInvoice.invoice_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.newInvoice.invoice_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "invoice_date", $$v)
                              },
                              expression: "newInvoice.invoice_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0",
                          attrs: {
                            label: _vm.$t("invoices.ref_number"),
                            error: _vm.invoiceNumError,
                            required: "",
                          },
                        },
                        [
                          _c(
                            "sw-input",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                invalid: _vm.$v.invoiceNumAttribute.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.invoiceNumAttribute.$touch()
                                },
                              },
                              model: {
                                value: _vm.invoiceNumAttribute,
                                callback: function ($$v) {
                                  _vm.invoiceNumAttribute = $$v
                                },
                                expression: "invoiceNumAttribute",
                              },
                            },
                            [
                              _c("hashtag-icon", {
                                staticClass: "h-4 ml-1 text-gray-500",
                                attrs: { slot: "leftIcon" },
                                slot: "leftIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0",
                          attrs: {
                            label: _vm.$t("invoices.invoice_number"),
                            error: _vm.referenceError,
                          },
                        },
                        [
                          _c(
                            "sw-input",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                invalid:
                                  _vm.$v.newInvoice.reference_number.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.newInvoice.reference_number.$touch()
                                },
                              },
                              model: {
                                value: _vm.newInvoice.reference_number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newInvoice,
                                    "reference_number",
                                    $$v
                                  )
                                },
                                expression: "newInvoice.reference_number",
                              },
                            },
                            [
                              _c("hashtag-icon", {
                                staticClass: "h-4 ml-1 text-gray-500",
                                attrs: { slot: "leftIcon" },
                                slot: "leftIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0 col-span-1",
                          attrs: {
                            label: _vm.$t("invoices.document_type"),
                            error: _vm.documentTypeError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            ref: "baseSelect",
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.documentTypes,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t(
                                "invoices.select_a_document_type"
                              ),
                              label: "description",
                              "track-by": "code",
                              invalid: _vm.$v.newInvoice.document_type.$error,
                            },
                            on: {
                              select: function (val) {
                                return _vm.onSelectDocumentType(val)
                              },
                            },
                            model: {
                              value: _vm.selectedDocumentType,
                              callback: function ($$v) {
                                _vm.selectedDocumentType = $$v
                              },
                              expression: "selectedDocumentType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.structs_enabled
                        ? _c(
                            "sw-input-group",
                            {
                              staticClass: "lg:mt-0 col-span-1",
                              attrs: {
                                label: _vm.$t(
                                  "settings.structure.invoice_code"
                                ),
                                error: _vm.structureError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                ref: "baseSelect",
                                staticClass: "mt-2",
                                attrs: {
                                  options: _vm.structs,
                                  searchable: true,
                                  "show-labels": false,
                                  placeholder: _vm.$t(
                                    "settings.structure.select_a_structure"
                                  ),
                                  label: "formattedStructure",
                                  "track-by": "id",
                                },
                                on: {
                                  select: function (val) {
                                    return _vm.onSelectStructure(val)
                                  },
                                },
                                model: {
                                  value: _vm.selectedStructure,
                                  callback: function ($$v) {
                                    _vm.selectedStructure = $$v
                                  },
                                  expression: "selectedStructure",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0 col-span-2",
                          attrs: {
                            label: _vm.$t("invoices.description"),
                            error: _vm.descriptionError,
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.newInvoice.description.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newInvoice.description.$touch()
                              },
                            },
                            model: {
                              value: _vm.newInvoice.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "description", $$v)
                              },
                              expression: "newInvoice.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0 col-span-1",
                          attrs: { label: _vm.$t("invoices.reverse_charge") },
                        },
                        [
                          _c("sw-switch", {
                            staticClass: "mt-0",
                            staticStyle: { "margin-top": "-10px" },
                            model: {
                              value: _vm.newInvoice.reverse_charge,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "reverse_charge", $$v)
                              },
                              expression: "newInvoice.reverse_charge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "w-full text-center item-table" },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "16%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "14%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "9%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "16%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "9%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "7%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "7%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "7%" } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "thead",
                    {
                      staticClass:
                        "bg-white border border-gray-200 border-solid",
                    },
                    [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", { staticClass: "pl-12" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tc("invoices.item.category", 2)) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tc("invoices.item.subcategory", 2)
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tc("invoices.item.description")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.item.price")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.item.vat")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-4 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.item.amount")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.item.enpav")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.item.vatfc")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.item.vatind")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      staticClass: "item-body",
                      attrs: { tag: "tbody", handle: ".handle" },
                      model: {
                        value: _vm.newInvoice.items,
                        callback: function ($$v) {
                          _vm.$set(_vm.newInvoice, "items", $$v)
                        },
                        expression: "newInvoice.items",
                      },
                    },
                    _vm._l(_vm.newInvoice.items, function (item, index) {
                      return _c("invoice-item", {
                        key: item.id,
                        attrs: {
                          index: index,
                          "item-data": item,
                          "invoice-items": _vm.newInvoice.items,
                          currency: _vm.currency,
                          "tax-per-item": _vm.taxPerItem,
                          "discount-per-item": _vm.discountPerItem,
                        },
                        on: {
                          remove: _vm.removeItem,
                          update: _vm.updateItem,
                          itemValidate: _vm.checkItemsData,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                  on: { click: _vm.addItem },
                },
                [
                  _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("invoices.add_item")) + "\n    "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "block my-10 invoice-foot lg:justify-between lg:flex lg:items-start",
                },
                [
                  _c("div", { staticClass: "w-full lg:w-2/5" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-2 flex items-center w-full justify-between",
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("invoices.deadlines")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex text-xs cursor-pointer text-primary-400 hover:bg-gray-200 ml-5 font-semibold",
                            on: { click: _vm.addDeadline },
                          },
                          [
                            _c("plus-sm-icon"),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.add_deadline")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      { staticClass: "w-full text-center item-table" },
                      [
                        _c("colgroup", [
                          _c("col", { staticStyle: { width: "30%" } }),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "20%" } }),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "35%" } }),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "5%" } }),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "10%" } }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "thead",
                          {
                            staticClass:
                              "bg-white border border-gray-200 border-solid",
                          },
                          [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", { staticClass: "pl-12" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("invoices.deadline.date")
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("invoices.deadline.amount")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("invoices.deadline.method")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("th", {
                                staticClass:
                                  "px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                              }),
                              _vm._v(" "),
                              _c("th", {
                                staticClass:
                                  "px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "draggable",
                          {
                            staticClass: "deadline-body",
                            attrs: { tag: "tbody", handle: ".handle" },
                            model: {
                              value: _vm.newInvoice.deadlines,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "deadlines", $$v)
                              },
                              expression: "newInvoice.deadlines",
                            },
                          },
                          _vm._l(
                            _vm.newInvoice.deadlines,
                            function (deadline, index) {
                              return _c("deadline", {
                                key: deadline.id,
                                attrs: {
                                  index: index,
                                  "deadline-data": deadline,
                                  "invoice-deadlines": _vm.newInvoice.deadlines,
                                  currency: _vm.currency,
                                  "invoice-total": _vm.total,
                                  "is-edit": _vm.isEdit,
                                  documentType: _vm.newInvoice.document_type,
                                  payment_methods: _vm.payment_methods,
                                  ritenuta_val:
                                    deadline.ritenuta === 1
                                      ? _vm.newInvoice.ritenuta_val
                                      : null,
                                },
                                on: {
                                  update: _vm.updateDeadline,
                                  remove: _vm.removeDeadline,
                                  deadlineValidate: _vm.checkDeadlinesData,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [_vm._v(_vm._s(_vm.$t("invoices.net_total")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.subtotal,
                                      _vm.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.allTaxes, function (tax) {
                        return _c(
                          "div",
                          {
                            key: tax.tax_type_id,
                            staticClass:
                              "flex items-center justify-between w-full",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  _vm._s(tax.name) +
                                    " - " +
                                    _vm._s(tax.percent) +
                                    "%\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                                staticStyle: { "font-size": "18px" },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        tax.amount,
                                        _vm.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.enpav != 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.enpav")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                  staticStyle: { "font-size": "18px" },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.enpav,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("invoices.total")) +
                                  " " +
                                  _vm._s(_vm.$t("invoices.document")) +
                                  ":\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center m-0 text-lg uppercase text-black",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.totalDocument,
                                      _vm.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full mt-2",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [_vm._v(_vm._s(_vm.$t("invoices.ritenuta")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { width: "105px" },
                            },
                            [
                              _c("sw-money", {
                                attrs: {
                                  currency: _vm.ritenutaCurrency,
                                  invalid:
                                    _vm.$v.newInvoice.ritenuta_val.$error,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.newInvoice.ritenuta_val.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.ritenuta,
                                  callback: function ($$v) {
                                    _vm.ritenuta = $$v
                                  },
                                  expression: "ritenuta",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$v.newInvoice.ritenuta_val.$error
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mt-2",
                            },
                            [
                              _c("label", {
                                staticClass:
                                  "text-sm font-semibold leading-5 text-gray-500 uppercase",
                              }),
                              _vm._v(" "),
                              _vm.$v.newInvoice.ritenuta_val.$error
                                ? _c("div", [
                                    !_vm.$v.newInvoice.ritenuta_val.between
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "validation.ritenuta_between"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (_vm.taxPerItem ? "NO" : null)
                        ? _c(
                            "div",
                            _vm._l(_vm.newInvoice.taxes, function (tax, index) {
                              return _c("tax", {
                                key: tax.id,
                                attrs: {
                                  index: index,
                                  total: _vm.subtotalWithDiscount,
                                  tax: tax,
                                  taxes: _vm.newInvoice.taxes,
                                  currency: _vm.currency,
                                  "total-tax": _vm.totalSimpleTax,
                                },
                                on: {
                                  remove: _vm.removeInvoiceTax,
                                  update: _vm.updateTax,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                        ? _c(
                            "sw-popup",
                            {
                              ref: "taxModal",
                              staticClass:
                                "my-3 text-sm font-semibold leading-5 text-primary-400",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "float-right pt-2 pb-5",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            + " +
                                      _vm._s(_vm.$t("invoices.add_tax")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("tax-select-popup", {
                                attrs: { taxes: _vm.newInvoice.taxes },
                                on: { select: _vm.onSelectTax },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("invoices.total")) +
                                  " " +
                                  _vm._s(_vm.$t("invoices.amount")) +
                                  ":\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center text-lg uppercase text-primary-400",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.total,
                                      _vm.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isEdit
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full pt-2 mt-2",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("estimates.amount_due")) +
                                      ":\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center text-lg uppercase text-primary-400",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.dueAmount,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }