<template>
  <div class="p-4 bg-white rounded shadow-md">
    <form id="loginForm" @submit.prevent="validateBeforeSubmit" class="space-y-4">
      <sw-input-group :label="$t('login.email')" :error="emailError" required>
        <sw-input
          :invalid="$v.loginData.email.$error"
          :placeholder="$t(login.login_placeholder)"
          v-model="loginData.email"
          focus
          type="email"
          name="email"
          @input="$v.loginData.email.$touch()"
        />
      </sw-input-group>

      <sw-input-group :label="$t('login.password')" :error="passwordError" required>
        <sw-input
          v-model="loginData.password"
          :invalid="$v.loginData.password.$error"
          :type="getInputType"
          name="password"
          @input="$v.loginData.password.$touch()"
        >
          <template #rightIcon>
            <eye-off-icon
              v-if="isShowPassword"
              class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
              @click="isShowPassword = !isShowPassword"
            />
            <eye-icon
              v-else
              class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
              @click="isShowPassword = !isShowPassword"
            />
          </template>
        </sw-input>
      </sw-input-group>

      <div class="flex flex-col space-y-2">
        <router-link to="forgot-password" class="text-sm text-primary-400 hover:text-gray-700">
          {{ $t('login.forgot_password') }}
        </router-link>
      </div>

      <!-- reCAPTCHA container -->
      <div class="flex justify-center mt-4" id="recaptcha-wrapper">
        <div id="g-recaptcha" class="g-recaptcha"></div>
      </div>

      <sw-button :loading="isLoading" :disabled="isLoading" type="submit" variant="primary" class="w-full">
        {{ $t('login.login') }}
      </sw-button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EyeIcon, EyeOffIcon } from '@vue-hero-icons/outline'
import IconFacebook from '@/components/icon/facebook'
import IconTwitter from '@/components/icon/twitter'
import IconGoogle from '@/components/icon/google'

const { required, email, minLength } = require('vuelidate/lib/validators')

export default {
  name: 'LoginRecaptcha',
  components: {
    IconFacebook,
    IconTwitter,
    IconGoogle,
    EyeIcon,
    EyeOffIcon,
  },
  data() {
    return {
      siteKey: process.env.MIX_RECAPTCHA_SITE_KEY,
      loginData: {
        email: '',
        password: '',
        remember: '',
        recaptchaToken: ''
      },
      isShowPassword: false,
      isLoading: false,
    }
  },
  validations: {
    loginData: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  computed: {
    emailError() {
      if (!this.$v.loginData.email.$error) {
        return ''
      }
      if (!this.$v.loginData.email.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.loginData.email.email) {
        return this.$tc('validation.email_incorrect')
      }
      return ''
    },
    passwordError() {
      if (!this.$v.loginData.password.$error) {
        return ''
      }
      if (!this.$v.loginData.password.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.loginData.password.minLength) {
        return this.$tc(
          'validation.password_min_length',
          this.$v.loginData.password.$params.minLength.min,
          { count: this.$v.loginData.password.$params.minLength.min },
        )
      }
      return ''
    },
    getInputType() {
      return this.isShowPassword ? 'text' : 'password'
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    onRecaptchaVerified(token) {
      this.loginData.recaptchaToken = token
    },
    async validateBeforeSubmit() {
      this.$v.loginData.$touch()
      if (this.$v.$invalid) {
        return
      }
      // Controlla se l'utente ha completato reCAPTCHA
      if (!this.loginData.recaptchaToken) {
        alert('Completa il reCAPTCHA prima di inviare il form!')
        return
      }
      this.isLoading = true
      try {
        await this.login(this.loginData)
        // Se il login va a buon fine
        this.$router.push('/admin/dashboard')
      } catch (error) {
        // Reset reCAPTCHA se disponibile
        if (typeof grecaptcha !== 'undefined') {
          grecaptcha.reset()
        }
        // Reset campi
        this.loginData.email = ''
        this.loginData.password = ''
        this.$v.loginData.$reset()
      } finally {
        this.isLoading = false
      }
    },
    loadRecaptchaScript() {
      const existingScript = document.getElementById('recaptcha-script')
      if (!existingScript) {
        const script = document.createElement('script')
        script.id = 'recaptcha-script'
        // Carichiamo la versione "explicit" per il rendering manuale
        script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaApiLoaded&render=explicit'
        script.async = true
        script.defer = true
        document.head.appendChild(script)
      } else {
        // Se lo script è già caricato, richiamiamo direttamente la funzione di init
        if (typeof grecaptcha !== 'undefined') {
          this.initRecaptcha()
        }
      }
    },
    initRecaptcha() {
      // Rendi e configura reCAPTCHA dopo che l'API è pronta
      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.ready(() => {
          grecaptcha.render('g-recaptcha', {
            sitekey: this.siteKey,
            callback: this.onRecaptchaVerified,
          })
        })
      }
    },
  },
  mounted() {
    // Definisci la callback globale per l'API
    window.onRecaptchaApiLoaded = () => {
      this.initRecaptcha()
    }

    // Carichiamo lo script
    this.loadRecaptchaScript()
  },
}
</script>

<style scoped>
#recaptcha-wrapper {
  width: 100%;
}

@media (max-width: 600px) {
  /* Ridimensiona il recaptcha nei dispositivi mobili */
  .g-recaptcha {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
</style>
